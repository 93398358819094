import React, { useState, useEffect } from "react";
import Navbar from "../Navbar";
import chapterFile from "./chapterFile.png";
import axios from "axios";
import SectionDetail from "../SectionDetail";
import Headline from "../heading";
import { Tab, Tabs } from "react-bootstrap";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import { message } from "antd";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

const Question = (props) => {
  const classes = useStyles();
  const [opens, setOpens] = React.useState(false);

  //   const handleClicks = () => {
  //     setOpens(true);
  //   };

  const handleCloses = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpens(false);
  };

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
    submitme();
    // pdfcheck();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [data, setData] = useState([]);

  const [name, setName] = React.useState(null);

  useEffect(() => {
    const token = localStorage.Ltoken;

    const headers = {
      "Content-Type": "application/json",
      AUTHORIZATION: `Token ${token}`,
    };
    // pdfcheck();
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/learning/api/upload-answers/?test_series=${props.id}`,
        { headers: headers }
      )
      .then((response) => {
        //     const de=[]
        //     response.data.data.map(d=>
        //         de.push({"id":d.id,"answer": d.answer}))

        //    setData(de)
        setData(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  //test

  // const pdfcheck =()=>
  // {
  //   const token = localStorage.Ltoken

  // const headers = {
  //   "Content-Type": "application/json",
  //   "AUTHORIZATION": `Token ${token}`

  //   }
  //   axios.get( `${process.env.REACT_APP_API_URL}/learning/api/upload-pdf-answers/?test_series=${props.id}`, {headers:headers} )
  //   .then( response => {
  //             const extract_name= response.data.data[0].answersheet!='N/A'?response.data.data[0].answersheet.substring(response.data.data[0].answersheet.lastIndexOf("/")+1):"None"
  //             setName(extract_name);

  //   } )
  //   .catch(error => {
  //     console.log(error);
  //   });
  // }

  //test

  const answers = (event) => {
    const id = parseInt(event.target.name);
    const answer = event.target.value;
    const question = event.currentTarget.dataset.id;
    //   console.log(event.target.name)
    //   console.log(event.target.value)
    //   console.log(event.currentTarget.dataset.id)

    // const objIndex = data.findIndex(obj => obj.id==id);
    // const updatedObj = { ...data[objIndex], "answer": answer};
    // const updatedData = [
    //     ...data.slice(0, objIndex),
    //     updatedObj,
    //     ...data.slice(objIndex + 1),
    //   ];
    //   setData(updatedData)

    const newState = data.map((obj) =>
      obj.id === id ? { ...obj, answer: answer } : obj
    );
    setData(newState);
  };

  const submitme = (event = null) => {
    //    console.log((typeof( Object.values(answer))))
    //     let ds=[]
    //     Object.values(answer).map((d,i)=>
    //     {
    //         ds.push({"question_number":i+1,
    //         "answer":d
    //     })

    //     })

    const d = data;
    let datas = {
      test_id: props.id,
      data: d,
    };

    const token = localStorage.Ltoken;

    const headers = {
      AUTHORIZATION: `Token ${token}`,
    };
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/learning/api/upload-answers/`,
        datas,
        { headers: headers }
      )
      .then((response) => {
        if (response.statusText === "OK") {
          setOpens(true);

          //    props.history.push("/assignment");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const finalsubmit = (event) => {
    // message.loading("Submitting your answers..",-1)

    // setOpens(true);
    //    console.log((typeof( Object.values(answer))))
    //     let ds=[]
    //     Object.values(answer).map((d,i)=>
    //     {
    //         ds.push({"question_number":i+1,
    //         "answer":d
    //     })

    //     })

    // pdfcheck();
    // if(name!=null && name!='None'){
    // message.loading("Submitting your answers..")

    //   const token = localStorage.Ltoken

    //   const headers = {
    //       "AUTHORIZATION": `Token ${token}`

    //     }
    //     submitme()

    //     axios.post( `${process.env.REACT_APP_API_URL}/learning/api/test-series-finished-answer/?test_series=${props.id}&is_finished=True`,null, {headers:headers} )
    //     .then( response => {
    //       if(response.statusText==='OK')
    //       {
    //           message.destroy()
    //           message.success("Answer submitted successfully")

    //           props.history.push("/assignment");
    //       }
    //     } )
    //     .catch(error => {
    //         console.log(error);
    //         message.destroy()
    //         message.error("Some error occured..")
    //     });

    //   }
    //   else
    //   message.error("Please upload your answersheet first.")

    const token = localStorage.Ltoken;

    const headers = {
      AUTHORIZATION: `Token ${token}`,
    };

    axios
      .get(
        `${process.env.REACT_APP_API_URL}/learning/api/upload-pdf-answers/?test_series=${props.id}`,
        { headers: headers }
      )
      .then((response) => {
        const extract_name =
          response.data.data[0].answersheet != "N/A"
            ? response.data.data[0].answersheet.substring(
                response.data.data[0].answersheet.lastIndexOf("/") + 1
              )
            : "None";

        if (extract_name != null && extract_name != "None") {
          message.loading("Submitting your answers..");
          axios
            .post(
              `${process.env.REACT_APP_API_URL}/learning/api/test-series-finished-answer/?test_series=${props.id}&is_finished=True`,
              null,
              { headers: headers }
            )
            .then((response) => {
              if (response) {
                handleClose();
                message.destroy();
                message.success("Answer submitted successfully");

                props.history.push("/assignment");
              }
            })
            .catch((error) => {
              console.log(error);
              message.destroy();
              message.error("Some error occured..");
            });
        } else {
          message.error("Please upload your answersheet first.");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="col-md-6 col-sm-6 p-0 ">
      <div classname="row  mb-5">
        <div className="col ">
          <Button
            variant="contained"
            color="secondary"
            onClick={handleClickOpen}
          >
            Final Submission
          </Button>
        </div>
      </div>
      <div
        className="left-side col mt-5 testing"
        style={{ height: "100vh", overflow: "auto" }}
      >
        <ul className="list-group">
          {data.map((d) => (
            <li className="list-group-item sec1">
              Q{d.question_no}.
              <textarea
                rows="8"
                style={{ width: "100%" }}
                name={d.id}
                data-id={d.question_no}
                className="pr-3"
                value={d.answer}
                onChange={answers}
              />
            </li>
          ))}
        </ul>
      </div>
      <div className="row ">
        <div className="col-7"></div>
        <div className="col-4 mr-3">
          <Button
            variant="contained"
            color="secondary"
            fullWidth={true}
            size="large"
            onClick={submitme}
          >
            Save{" "}
          </Button>
        </div>
        {/* <div className="col">
        <Button variant="outlined" color="primary" onClick={handleClickOpen}>
       Final Submission
      </Button>
      </div> */}
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Are you sure you want to make final submission?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              After this you will be unable to submit answer again.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Back
            </Button>
            <Button onClick={finalsubmit} color="primary" autoFocus>
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </div>

      <div className={classes.root}>
        {/* <Button variant="outlined" onClick={handleClicks}>
       Save
      </Button> */}
        <Snackbar open={opens} autoHideDuration={6000} onClose={handleCloses}>
          <Alert onClose={handleCloses} severity="success">
            Answer Saved!
          </Alert>
        </Snackbar>
      </div>
    </div>
  );
};
export default Question;
